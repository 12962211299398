<template>
  <operation-wrapper :price="price" :type="OperationType.PAYOUT">
    <form class="form" @submit.prevent="handleOnSubmit">
      <div class="form__container form__container--payout">
        <input-wrapper
          :condition="validFields.account_number"
          class="form__input"
        >
          <input-crypto
            v-model="form.account_number"
            @input="validateCryptoWallet(form.account_number)"
          />
        </input-wrapper>
        <p class="form__addition-text">
          <sup>*</sup>Поддерживаемая сеть - ERC20, TRC20
        </p>
      </div>
      <v-divider class="divider-section" />
      <VButton
        v-if="payoutResponse"
        :disabled="disabled || !isCryptoPayoutFormValid"
        :loading="isLoading"
        class="form__submit form__submit--crypto"
        type="submit"
      >
        Вывести {{ price }}
      </VButton>
    </form>
  </operation-wrapper>
</template>

<script lang="ts" setup>
import { TPayoutForm } from "@/types/payout";
import { reactive, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useValidation } from "@/use/useValidation";
import { OperationType } from "@/helpers/enums";
import VButton from "@/components/VButton.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import InputCrypto from "@/components/inputs/InputCrypto.vue";
import VDivider from "@/components/VDivider.vue";
import OperationWrapper from "@/components/OperationWrapper.vue";
import { usePayment } from "@/use/usePayment";

const { payoutResponse, price, updatePayment } = usePayment();

const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const form = reactive<TPayoutForm>({
  tx: payoutResponse.value?.tx?.tx as string,
  account_number: "",
  payment_method: payoutResponse.value?.tx.payout.means_of_payment_type
    .code as string,
});
const { payout } = useApi();

const { validFields, isCryptoPayoutFormValid, validateCryptoWallet } =
  useValidation();

const handleOnSubmit = async () => {
  disabled.value = true;
  isLoading.value = true;

  try {
    await payout.process(form);
    await updatePayment(payoutResponse.value?.tx.tx as string);
  } catch (e) {
    console.error(e);
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};
</script>
