import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "input-wrapper__svg input-wrapper__svg--left"
}
const _hoisted_3 = ["xlink:href"]
const _hoisted_4 = {
  key: 1,
  class: "input-wrapper__svg input-wrapper__svg--left"
}
const _hoisted_5 = {
  key: 2,
  class: "input-wrapper__svg input-wrapper__svg--right success"
}
const _hoisted_6 = {
  key: 3,
  class: "input-wrapper__svg input-wrapper__svg--right error"
}

import { PropType } from "vue";
import { TCardTypes } from "@/types/common";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputWrapper',
  props: {
  condition: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  cardType: {
    type: String as PropType<TCardTypes>,
    default: "",
  },
  isPhone: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (__props.cardType)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_2, [
          _createElementVNode("use", {
            "xlink:href": `#${__props.cardType}`
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (__props.isPhone)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[0] || (_cache[0] = [
          _createElementVNode("use", { "xlink:href": "#phone" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (__props.condition)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[1] || (_cache[1] = [
          _createElementVNode("use", { "xlink:href": "#success" }, null, -1)
        ])))
      : (_openBlock(), _createElementBlock("svg", _hoisted_6, _cache[2] || (_cache[2] = [
          _createElementVNode("use", { "xlink:href": "#wrong" }, null, -1)
        ])))
  ]))
}
}

})