import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

import { vMaska } from "maska/vue";
import { computed, PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputPhone',
  props: {
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleOnChange = (event: Event) => {
  value.value = (event.target as HTMLInputElement).value.replace(/[^+\d]/g, "");
};

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    value: value.value,
    class: "input input--card",
    placeholder: "Введите номер телефона",
    type: "tel",
    onInput: handleOnChange
  }, null, 40, _hoisted_1)), [
    [_unref(vMaska), '+7 ### ### ## ##']
  ])
}
}

})