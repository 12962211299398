<template>
  <operation-wrapper
    :price="price"
    :type="OperationType.PAYMENT"
    class="form-qr"
  >
    <the-loader v-if="loading" />
    <div v-else class="form-qr__wrapper">
      <QrcodeVue :size="260" :value="paymentResponse.info.qr_data?.qr_url" />
      <a
        :href="paymentResponse.info.qr_data?.qr_url"
        class="button form-qr__button"
        target="_blank"
        >Оплатить в приложении</a
      >
    </div>
  </operation-wrapper>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { OperationType } from "@/helpers/enums";
import OperationWrapper from "@/components/OperationWrapper.vue";
import { useApi } from "@/plugins/api";
import QrcodeVue from "qrcode.vue";
import TheLoader from "@/components/TheLoader.vue";
import { usePayment } from "@/use/usePayment";

const { paymentResponse, price, updatePayment } = usePayment();
const { payment } = useApi();
const loading = ref(true);

const process = async () => {
  try {
    loading.value = true;
    const { data } = await payment.processP2P({
      tx: paymentResponse.value?.info.tx as string,
    });
    await updatePayment(data.info.tx);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

onMounted(process);
</script>

<style lang="scss">
.form-qr {
  @import "@scss/utils.scss";
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;

    @include mq($sz-mobile) {
      row-gap: 24px;
    }
  }
}
</style>
