import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form__container form__container--p2p" }

import OperationWrapper from "@/components/OperationWrapper.vue";
import { OperationType } from "@/helpers/enums";
import { reactive, ref } from "vue";
import { TPaymentForm } from "@/types/payment";
import InputCard from "@/components/inputs/InputCard.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import { TCardTypes, TNullable } from "@/types/common";
import { useApi } from "@/plugins/api";
import { useValidation } from "@/use/useValidation";
import VButton from "@/components/VButton.vue";
import VDivider from "@/components/VDivider.vue";
import { usePayment } from "@/use/usePayment";


export default /*@__PURE__*/_defineComponent({
  __name: 'P2PForm',
  setup(__props) {

const { paymentResponse, updatePayment, price } = usePayment();
const cardType = ref<TNullable<TCardTypes>>("unknown");
const form = reactive<TPaymentForm>({
  tx: paymentResponse.value?.info?.tx as string,
  pan: "",
});
const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);

const { payment } = useApi();
const { validFields, isP2PFormValid, validatePan } = useValidation();

const handleOnSubmit = async () => {
  isLoading.value = true;

  try {
    const { data } = await payment.payP2P(form);
    if (data?.info?.p2p_card_data) {
      localStorage.setItem(
        "p2p.card",
        data.info.p2p_card_data.target_card_number
      );
      localStorage.setItem("p2p.expires", data.info.p2p_card_data.valid_till);
    }
    await updatePayment(data.info.tx);
  } catch (e) {
    console.error(e);
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: _unref(price),
    type: _unref(OperationType).PAYMENT,
    "has-cards-block": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "form",
        onSubmit: _withModifiers(handleOnSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(InputWrapper, {
            "card-type": cardType.value,
            condition: _unref(validFields).pan,
            class: "form__input form__input--card"
          }, {
            default: _withCtx(() => [
              _createVNode(InputCard, {
                modelValue: form.pan,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.pan) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_unref(validatePan)(form.pan))),
                "onUpdate:cardType": _cache[2] || (_cache[2] = ($event: any) => (cardType.value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["card-type", "condition"])
        ]),
        _createVNode(VDivider, { class: "divider-section" }),
        (_unref(paymentResponse))
          ? (_openBlock(), _createBlock(VButton, {
              key: 0,
              disabled: !_unref(isP2PFormValid) || disabled.value,
              loading: isLoading.value,
              class: "form__submit",
              type: "submit"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Продолжить ")
              ])),
              _: 1
            }, 8, ["disabled", "loading"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})