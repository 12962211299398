import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref } from "vue";
import { usePayment } from "@/use/usePayment";
import { EIntegrationType } from "@/helpers/enums";
import TheLoader from "@/components/TheLoader.vue";
import P2PInstructions from "@/components/form/P2PInstructions.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProcessPayment',
  setup(__props) {

const { paymentResponse } = usePayment();

const payed = ref<boolean>(false);
const isP2pPayment = computed(
  () =>
    paymentResponse.value?.info.payment.integration_type ===
      EIntegrationType.P2P_CARD && !payed.value
);

return (_ctx: any,_cache: any) => {
  return (isP2pPayment.value)
    ? (_openBlock(), _createBlock(P2PInstructions, {
        key: 0,
        onComplete: _cache[0] || (_cache[0] = ($event: any) => (payed.value = true))
      }))
    : (_openBlock(), _createBlock(TheLoader, { key: 1 }))
}
}

})