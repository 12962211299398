<template>
  <operation-wrapper
    :price="price"
    :type="OperationType.PAYOUT"
    has-cards-block
    only-pci
  >
    <SBPBanks
      v-if="step === ESBPPayoutStep.BANKS"
      @select-bank="changeBank"
      @change-step="changeStep"
    />
    <SBPPayout v-else :bank="bank" />
  </operation-wrapper>
</template>

<script lang="ts" setup>
import OperationWrapper from "@/components/OperationWrapper.vue";
import { ESBPPayoutStep, OperationType } from "@/helpers/enums";
import SBPBanks from "@/components/form/SBPPayout/SBPBanks.vue";
import SBPPayout from "@/components/form/SBPPayout/SBPPayout.vue";
import { usePayment } from "@/use/usePayment";
import { ref } from "vue";
import { TBank } from "@/types/payment";

const { price } = usePayment();

const step = ref(ESBPPayoutStep.BANKS);
const bank = ref<TBank>();

const changeStep = (value: ESBPPayoutStep) => {
  step.value = value;
};

const changeBank = (value: TBank) => {
  bank.value = value;
};
</script>

<style lang="scss"></style>
