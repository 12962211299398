import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card-types" }
const _hoisted_2 = ["xlink:href"]

import { computed, PropType } from "vue";

interface Card {
  icon: string;
  type: "card" | "standart";
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CardTypes',
  props: {
  onlyPci: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const cardTypes: Card[] = [
  { icon: "card-world", type: "card" },
  { icon: "master-card", type: "card" },
  { icon: "visa-card", type: "card" },
  { icon: "pci-card", type: "standart" },
];

const filteredCardTypes = computed(() => {
  return props.onlyPci
    ? cardTypes.filter((card) => card.type === "standart")
    : cardTypes;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCardTypes.value, (card) => {
      return (_openBlock(), _createElementBlock("svg", {
        key: card.icon,
        class: "card-types__icon"
      }, [
        _createElementVNode("use", {
          "xlink:href": `#${card.icon}`
        }, null, 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}
}

})