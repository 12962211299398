<template>
  <form class="form sbp-form" @submit.prevent="handleOnSubmit">
    <div class="form__container form__container--p2p sbp-form__container">
      <div class="sbp-form__requisites">
        <picture v-if="bank?.logo2">
          <img :alt="bank.title" :src="`${url}${bank.logo2}`" />
        </picture>
        <span v-else-if="bank?.title">{{ bank.title }}</span>
      </div>
      <input-wrapper
        :condition="validFields.account_number"
        class="form__input form__input--card"
        is-phone
      >
        <input-phone
          v-model="form.account_number"
          @input="validatePhone(form.account_number)"
        />
      </input-wrapper>
    </div>
    <v-divider class="divider-section" />
    <VButton
      v-if="payoutResponse"
      :disabled="!isSBPPayoutFormValid || disabled"
      :loading="isLoading"
      class="form__submit"
      type="submit"
    >
      Вывести {{ price }}
    </VButton>
  </form>
</template>

<script lang="ts" setup>
import { TPayoutForm } from "@/types/payout";
import { computed, PropType, reactive, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useValidation } from "@/use/useValidation";
import VButton from "@/components/VButton.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import VDivider from "@/components/VDivider.vue";
import InputPhone from "@/components/inputs/InputPhone.vue";
import { TBank } from "@/types/payment";
import { usePayment } from "@/use/usePayment";

const props = defineProps({
  bank: {
    type: Object as PropType<TBank>,
    default: null,
  },
});

const { payoutResponse, updatePayment, price } = usePayment();

const url = computed(() => process.env.VUE_APP_API_URL);
const form = reactive<TPayoutForm>({
  tx: payoutResponse.value?.tx.tx as string,
  bank_code: props.bank?.code,
  account_number: "+7",
  payment_method: payoutResponse.value?.tx.payout.means_of_payment_type
    .code as string,
});
const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const { payout } = useApi();

const { validFields, isSBPPayoutFormValid, validatePhone } = useValidation();

const handleOnSubmit = async () => {
  disabled.value = true;
  isLoading.value = true;

  try {
    const { data } = await payout.process(form);
    await updatePayment(data.tx.tx);
  } catch (e) {
    console.error(e);
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};
</script>

<style lang="scss">
@import "@scss/utils";

.sbp-form {
  &__container {
    gap: 16px !important;
  }

  &__requisites {
    display: grid;
    gap: 8px;
    padding: 16px;
    border-radius: 10px;
    background-color: $wrapper-bg;

    > picture {
      display: grid;
      justify-content: start;
      height: 24px;
    }

    > span {
      color: $alter-text;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
