<template>
  <p2-p-instructions v-if="isP2pPayment" @complete="payed = true" />
  <the-loader v-else />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { usePayment } from "@/use/usePayment";
import { EIntegrationType } from "@/helpers/enums";
import TheLoader from "@/components/TheLoader.vue";
import P2PInstructions from "@/components/form/P2PInstructions.vue";

const { paymentResponse } = usePayment();

const payed = ref<boolean>(false);
const isP2pPayment = computed(
  () =>
    paymentResponse.value?.info.payment.integration_type ===
      EIntegrationType.P2P_CARD && !payed.value
);
</script>
