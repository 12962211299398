<template>
  <input
    v-maska="'+7 ### ### ## ##'"
    :value="value"
    class="input input--card"
    placeholder="Введите номер телефона"
    type="tel"
    @input="handleOnChange"
  />
</template>

<script lang="ts" setup>
import { vMaska } from "maska/vue";
import { computed, PropType } from "vue";

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const handleOnChange = (event: Event) => {
  value.value = (event.target as HTMLInputElement).value.replace(/[^+\d]/g, "");
};

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss">
@import "input";
</style>
