<template>
  <div class="appeal-status">
    <div class="appeal-status__container">
      <h2 class="appeal-status__title">Обращение в службу поддержки</h2>
      <span class="appeal-status__payment-number"> Номер платежа </span>
      <p class="appeal-status__number">{{ paymentResponse.info.tx }}</p>
      <p class="appeal-status__text">Статус заявки</p>
      <info-block :title="appealStatus.title" :type="appealStatus.type" />
      <v-divider class="appeal-status__divider" />
      <div class="appeal-status__btns">
        <a
          v-if="paymentResponse?.info?.payment?.fail_url"
          :href="paymentResponse.info.payment.fail_url"
          class="button"
        >
          Вернуться в магазин
        </a>
        <VButton transparent @click="emit('close')">
          Вернуться к платежу
        </VButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InfoBlock from "@/components/InfoBlock.vue";
import VDivider from "@/components/VDivider.vue";
import { computed, PropType } from "vue";
import { type TAppeal } from "@/types/payment";
import VButton from "@/components/VButton.vue";
import { usePayment } from "@/use/usePayment";

const props = defineProps({
  appeal: {
    type: Object as PropType<TAppeal>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: "close"): void;
}>();

const { paymentResponse } = usePayment();

const appealStatuses = {
  create: {
    title: "Ваша заявка создана",
    type: "warning",
  },
  process: {
    title: "Ваша заявка в обработке",
    type: "warning",
  },
  success: {
    title: "Ваша заявка успешно обработана",
    type: "success",
  },
  reversal: {
    title: "Ваша заявка отменена",
    type: "error",
  },
};

const appealStatus = computed(() => appealStatuses[props.appeal.status]);
</script>

<style lang="scss">
@import "@scss/utils";

.appeal-status {
  display: grid;
  gap: 26px;
  max-width: 336px;

  @include mq(600px) {
    gap: 16px;
  }

  @include mq($sz-mobile) {
    max-width: none;
    padding: 16px;
    border-radius: 20px;
    background-color: $alter-bg;
    box-shadow: 0 12px 24px rgba($shadow-modal, 0.08);
    margin-block: auto;
  }

  &__container {
    display: grid;
    align-content: start;

    &--info {
      gap: 26px;

      @include mq(600px) {
        gap: 16px;
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 28px;
  }

  &__payment-number,
  &__text {
    margin-bottom: 4px;
    color: $alter-text;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
  }

  &__number {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  &__rules {
    margin-bottom: 8px;
    color: $alter-text;
    font-size: 14px;
    line-height: 20px;
  }

  &__divider {
    margin-block: 26px;
  }

  &__btns {
    display: grid;
    gap: 16px;
  }
}
</style>
