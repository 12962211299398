import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "form-qr__wrapper"
}
const _hoisted_2 = ["href"]

import { onMounted, ref } from "vue";
import { OperationType } from "@/helpers/enums";
import OperationWrapper from "@/components/OperationWrapper.vue";
import { useApi } from "@/plugins/api";
import QrcodeVue from "qrcode.vue";
import TheLoader from "@/components/TheLoader.vue";
import { usePayment } from "@/use/usePayment";


export default /*@__PURE__*/_defineComponent({
  __name: 'QRForm',
  setup(__props) {

const { paymentResponse, price, updatePayment } = usePayment();
const { payment } = useApi();
const loading = ref(true);

const process = async () => {
  try {
    loading.value = true;
    const { data } = await payment.processP2P({
      tx: paymentResponse.value?.info.tx as string,
    });
    await updatePayment(data.info.tx);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

onMounted(process);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: _unref(price),
    type: _unref(OperationType).PAYMENT,
    class: "form-qr"
  }, {
    default: _withCtx(() => [
      (loading.value)
        ? (_openBlock(), _createBlock(TheLoader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(QrcodeVue, {
              size: 260,
              value: _unref(paymentResponse).info.qr_data?.qr_url
            }, null, 8, ["value"]),
            _createElementVNode("a", {
              href: _unref(paymentResponse).info.qr_data?.qr_url,
              class: "button form-qr__button",
              target: "_blank"
            }, "Оплатить в приложении", 8, _hoisted_2)
          ]))
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})