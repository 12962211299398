import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { EModalType, EPayStatus } from "@/helpers/enums";
import { onMounted, ref } from "vue";
import { useModal } from "@/use/useModal";
import { usePayment } from "@/use/usePayment";
import PayForm from "@/components/form/PayForm.vue";
import SvgSprite from "@/components/SvgSprite.vue";
import TheModal from "@/components/TheModal.vue";
import TermsContent from "@/components/modal/TermsContent.vue";
import PolicyContent from "@/components/modal/PolicyContent.vue";
import TheLoader from "@/components/TheLoader.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import OperationResult from "@/components/form/OperationResult.vue";
import ProcessPayment from "@/components/form/ProcessPayment.vue";
import PayoutForm from "@/components/form/PayoutForm.vue";
import CryptoPayoutForm from "@/components/form/CryptoPayoutForm.vue";
import P2PForm from "@/components/form/P2PForm.vue";
import H2HP2PFlow from "@/components/form/H2HP2P/H2HP2PFlow.vue";
import QRForm from "@/components/form/QRForm.vue";
import ChangeSumModal from "@/components/modal/ChangeSumModal.vue";
import SBPFlow from "@/components/form/SBPPayout/SBPFlow.vue";

const UPDATE_PERIOD = 5000;


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { modal, onClose } = useModal();
const { loading, status, paymentInfo, updatePayment, isSumHasChanged, price } =
  usePayment();
const isSupport = ref(false);
const changeWrapper = (value: boolean) => (isSupport.value = value);

let interval: number | undefined;
onMounted(async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params.tx) {
    await paymentInfo(params.tx);
    interval = setInterval(() => {
      updatePayment(params.tx, interval);
    }, UPDATE_PERIOD);
  } else {
    loading.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        alt: "zenpay",
        class: "page__logo",
        height: "24",
        src: "/logo.svg",
        width: "154"
      }, null, -1)),
      _createVNode(BaseContainer, {
        support: isSupport.value,
        class: "page__main"
      }, {
        default: _withCtx(() => [
          (_unref(loading))
            ? (_openBlock(), _createBlock(TheLoader, { key: 0 }))
            : (_unref(status) === _unref(EPayStatus).H2H_P2P)
              ? (_openBlock(), _createBlock(H2HP2PFlow, {
                  key: 1,
                  onChangeWrapper: _cache[0] || (_cache[0] = ($event: any) => (changeWrapper($event)))
                }))
              : (_unref(status) === _unref(EPayStatus).QR)
                ? (_openBlock(), _createBlock(QRForm, { key: 2 }))
                : (_unref(status) === _unref(EPayStatus).FORM)
                  ? (_openBlock(), _createBlock(PayForm, { key: 3 }))
                  : (_unref(status) === _unref(EPayStatus).P2P_FORM)
                    ? (_openBlock(), _createBlock(P2PForm, { key: 4 }))
                    : (_unref(status) === _unref(EPayStatus).SBP)
                      ? (_openBlock(), _createBlock(SBPFlow, { key: 5 }))
                      : (_unref(status) === _unref(EPayStatus).PAYOUT)
                        ? (_openBlock(), _createBlock(PayoutForm, { key: 6 }))
                        : (_unref(status) === _unref(EPayStatus).PAYOUT_CRYPTO)
                          ? (_openBlock(), _createBlock(CryptoPayoutForm, { key: 7 }))
                          : (_unref(status) === _unref(EPayStatus).PROCESS)
                            ? (_openBlock(), _createBlock(ProcessPayment, { key: 8 }))
                            : (_unref(status) === _unref(EPayStatus).SUCCESS || _unref(status) === _unref(EPayStatus).FAIL)
                              ? (_openBlock(), _createBlock(OperationResult, { key: 9 }))
                              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["support"])
    ]),
    _createVNode(SvgSprite),
    (_unref(isSumHasChanged))
      ? (_openBlock(), _createBlock(ChangeSumModal, {
          key: 0,
          amount: _unref(price),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (isSumHasChanged.value = false))
        }, null, 8, ["amount"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_unref(modal).isOpen)
          ? (_openBlock(), _createBlock(TheModal, {
              key: 0,
              onHide: _unref(onClose)
            }, {
              title: _withCtx(() => [
                (_unref(modal).type === _unref(EModalType).TERMS)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Terms"))
                  : _createCommentVNode("", true),
                (_unref(modal).type === _unref(EModalType).POLICY)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Privacy policy"))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (_unref(modal).type === _unref(EModalType).TERMS)
                  ? (_openBlock(), _createBlock(TermsContent, { key: 0 }))
                  : _createCommentVNode("", true),
                (_unref(modal).type === _unref(EModalType).POLICY)
                  ? (_openBlock(), _createBlock(PolicyContent, { key: 1 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onHide"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}
}

})