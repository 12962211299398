<template>
  <div class="page">
    <img
      alt="zenpay"
      class="page__logo"
      height="24"
      src="/logo.svg"
      width="154"
    />
    <BaseContainer :support="isSupport" class="page__main">
      <TheLoader v-if="loading" />
      <H2HP2PFlow
        v-else-if="status === EPayStatus.H2H_P2P"
        @change-wrapper="changeWrapper($event)"
      />
      <QRForm v-else-if="status === EPayStatus.QR" />
      <PayForm v-else-if="status === EPayStatus.FORM" />
      <P2PForm v-else-if="status === EPayStatus.P2P_FORM" />
      <SBPFlow v-else-if="status === EPayStatus.SBP" />
      <PayoutForm v-else-if="status === EPayStatus.PAYOUT" />
      <CryptoPayoutForm v-else-if="status === EPayStatus.PAYOUT_CRYPTO" />
      <ProcessPayment v-else-if="status === EPayStatus.PROCESS" />
      <OperationResult
        v-else-if="status === EPayStatus.SUCCESS || status === EPayStatus.FAIL"
      />
    </BaseContainer>
  </div>
  <SvgSprite />
  <ChangeSumModal
    v-if="isSumHasChanged"
    :amount="price"
    @close="isSumHasChanged = false"
  />
  <Transition name="fade">
    <TheModal v-if="modal.isOpen" @hide="onClose">
      <template #title>
        <span v-if="modal.type === EModalType.TERMS">Terms</span>
        <span v-if="modal.type === EModalType.POLICY">Privacy policy</span>
      </template>
      <TermsContent v-if="modal.type === EModalType.TERMS" />
      <PolicyContent v-if="modal.type === EModalType.POLICY" />
    </TheModal>
  </Transition>
</template>

<script lang="ts" setup>
import { EModalType, EPayStatus } from "@/helpers/enums";
import { onMounted, ref } from "vue";
import { useModal } from "@/use/useModal";
import { usePayment } from "@/use/usePayment";
import PayForm from "@/components/form/PayForm.vue";
import SvgSprite from "@/components/SvgSprite.vue";
import TheModal from "@/components/TheModal.vue";
import TermsContent from "@/components/modal/TermsContent.vue";
import PolicyContent from "@/components/modal/PolicyContent.vue";
import TheLoader from "@/components/TheLoader.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import OperationResult from "@/components/form/OperationResult.vue";
import ProcessPayment from "@/components/form/ProcessPayment.vue";
import PayoutForm from "@/components/form/PayoutForm.vue";
import CryptoPayoutForm from "@/components/form/CryptoPayoutForm.vue";
import P2PForm from "@/components/form/P2PForm.vue";
import H2HP2PFlow from "@/components/form/H2HP2P/H2HP2PFlow.vue";
import QRForm from "@/components/form/QRForm.vue";
import ChangeSumModal from "@/components/modal/ChangeSumModal.vue";
import SBPFlow from "@/components/form/SBPPayout/SBPFlow.vue";

const { modal, onClose } = useModal();
const { loading, status, paymentInfo, updatePayment, isSumHasChanged, price } =
  usePayment();
const isSupport = ref(false);
const changeWrapper = (value: boolean) => (isSupport.value = value);

let interval: number | undefined;
const UPDATE_PERIOD = 5000;

onMounted(async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params.tx) {
    await paymentInfo(params.tx);
    interval = setInterval(() => {
      updatePayment(params.tx, interval);
    }, UPDATE_PERIOD);
  } else {
    loading.value = false;
  }
});
</script>

<style lang="scss">
@import "@scss/index.scss";

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 32px 40px;

  @include mq($sz-mobile) {
    padding: 24px 16px;
  }

  &__logo {
    width: 154px;

    @include mq($sz-mobile) {
      width: 116px;
      margin-bottom: 35px;
    }
  }

  &__main {
    margin: auto;

    @include mq($sz-mobile) {
      flex-grow: 1;
      margin-block: 0;
    }
  }
}
</style>
