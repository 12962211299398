<template>
  <the-loader v-if="loading" />
  <form v-else class="form p2p-form" @submit.prevent="handleOnSubmit">
    <div class="form__container form__container--p2p">
      <b class="p2p-form__subtitle">Выберите банк</b>
      <ul v-if="banks.length" class="p2p-form__grid">
        <li v-for="bank in banks" :key="bank.id">
          <button class="p2p-form__bank" @click="selectBank(bank)">
            <picture>
              <img :alt="bank.title" :src="`${url}${bank.logo1}`" />
            </picture>
            <span>{{ bank.title }}</span>
          </button>
        </li>
      </ul>
      <span v-else>Нет доступных банков</span>
    </div>
  </form>
</template>

<script lang="ts" setup>
import TheLoader from "@/components/TheLoader.vue";
import { computed, onMounted, ref } from "vue";
import type { TBank } from "@/types/payment";
import { useApi } from "@/plugins/api";
import { ESBPPayoutStep } from "@/helpers/enums";
import { usePayment } from "@/use/usePayment";

const emit = defineEmits<{
  (e: "selectBank", value: TBank): void;
  (e: "changeStep", value: number): void;
}>();

const { payoutResponse } = usePayment();

const { payout } = useApi();
const url = computed(() => process.env.VUE_APP_API_URL);
const loading = ref(false);
const banks = ref<TBank[]>([]);

const selectBank = (bank: TBank) => {
  emit("selectBank", bank);
  emit("changeStep", ESBPPayoutStep.FORM);
};

const loadBanks = async () => {
  try {
    loading.value = true;
    const { data } = await payout.banks(payoutResponse.value?.tx.tx as string);
    banks.value = data ?? [];
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

onMounted(loadBanks);
</script>

<style lang="scss">
@import "@scss/utils";

.p2p-form {
  margin-bottom: 26px;

  &__subtitle {
    margin-bottom: 16px;
    color: $alter-text;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.3px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 16px;
    list-style: none;
  }

  &__bank {
    display: grid;
    justify-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 10px;
    background-color: $wrapper-bg;

    > picture {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
    }

    > span {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
