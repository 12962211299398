import { ref } from "vue";

const isChangedSumShowedAlready = ref(false);

export const useCheckSumChanges = () => {
  const isSumHasChanged = ref(false);

  const checkSumChanges = (amount?: number, oldAmount?: number | null) => {
    if (
      !isChangedSumShowedAlready.value &&
      oldAmount !== null &&
      amount !== oldAmount
    ) {
      isSumHasChanged.value = true;
      isChangedSumShowedAlready.value = true;
    }
  };

  return {
    isSumHasChanged,
    checkSumChanges,
  };
};
