import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form__container form__container--p2p" }
const _hoisted_2 = {
  key: 0,
  class: "p2p-form__grid"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { key: 1 }

import TheLoader from "@/components/TheLoader.vue";
import { computed, onMounted, ref } from "vue";
import type { TBank, TH2HP2PPaymentForm } from "@/types/payment";
import { useApi } from "@/plugins/api";
import { usePayment } from "@/use/usePayment";


export default /*@__PURE__*/_defineComponent({
  __name: 'H2HP2PBanks',
  setup(__props) {

const { paymentResponse, updatePayment } = usePayment();
const form = ref<TH2HP2PPaymentForm>({
  tx: paymentResponse.value?.info?.tx as string,
  bank: -1,
});
const loading = ref(false);
const banks = ref<TBank[]>([]);
const url = computed(() => process.env.VUE_APP_API_URL);

const { payment } = useApi();
const handleOnSubmit = async (bankId: number | null) => {
  try {
    loading.value = true;
    form.value.bank = bankId;
    const { data } = await payment.processP2P(form.value);
    await updatePayment(data.info.tx);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const loadBanks = async () => {
  try {
    loading.value = true;
    const { data } = await payment.banks(
      paymentResponse.value?.info?.tx as string
    );
    banks.value = data ?? [];

    if (!banks.value.length) {
      await handleOnSubmit(null);
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

onMounted(loadBanks);

return (_ctx: any,_cache: any) => {
  return (loading.value)
    ? (_openBlock(), _createBlock(TheLoader, { key: 0 }))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        class: "form p2p-form",
        onSubmit: _withModifiers(handleOnSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("b", { class: "p2p-form__subtitle" }, "Выберите банк", -1)),
          (banks.value.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(banks.value, (bank) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: bank.id
                  }, [
                    _createElementVNode("button", {
                      class: "p2p-form__bank",
                      onClick: ($event: any) => (handleOnSubmit(bank.id))
                    }, [
                      _createElementVNode("picture", null, [
                        _createElementVNode("img", {
                          alt: bank.title,
                          src: `${url.value}${bank.logo1}`
                        }, null, 8, _hoisted_4)
                      ]),
                      _createElementVNode("span", null, _toDisplayString(bank.title), 1)
                    ], 8, _hoisted_3)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, "Нет доступных банков"))
        ])
      ], 32))
}
}

})