import { computed, ref } from "vue";
import { useApi } from "@/plugins/api";
import { TNullable, TStatus } from "@/types/common";
import { EIntegrationType, EPayStatus, OperationType } from "@/helpers/enums";
import { TPayoutResponse } from "@/types/payout";
import { TPaymentResponse } from "@/types/payment";
import { usePrice } from "@/use/usePrice";
import { FAIL_STATUES, SUCCESS_STATUES } from "@/helpers/constants";
import { useCheckSumChanges } from "@/use/useCheckSumChanges";

const { isSumHasChanged, checkSumChanges } = useCheckSumChanges();
const isPayout = ref(true);
const loading = ref<boolean>(true);
const status = ref<EPayStatus>(EPayStatus.TEST);
const type = ref<OperationType>(OperationType.PAYMENT);
const url = ref<TNullable<string>>(null);
const payoutResponse = ref<TNullable<TPayoutResponse>>(null);
const paymentResponse = ref<TNullable<TPaymentResponse>>(null);
const price = computed(() => {
  if (payoutResponse.value?.tx?.payout) {
    return usePrice(
      payoutResponse.value.tx.payout?.amount ?? 0,
      payoutResponse.value.tx.payout?.currency?.char_code
    );
  } else if (paymentResponse.value?.info?.payment) {
    return usePrice(
      paymentResponse.value.info.payment?.amount ?? 0,
      paymentResponse.value.info.payment?.currency?.char_code
    );
  }
  return null;
});

export const usePayment = () => {
  const { payment, payout } = useApi();

  const checkPayout = async (tx: string) => {
    const { data } = await payout.info(tx);

    if (!data.tx.payout) {
      isPayout.value = false;
      return;
    }

    type.value = OperationType.PAYOUT;
    payoutResponse.value = data;
    url.value = data.tx.payout.success_url;
    const payoutStatus = data.tx.payout.status;

    if (payoutStatus === "success") {
      status.value = EPayStatus.SUCCESS;
    } else if (
      payoutStatus === "error" ||
      payoutStatus === "reversal" ||
      payoutStatus === "antifraud_error" ||
      payoutStatus === "reversal_timeout" ||
      payoutStatus === "provider_timeout"
    ) {
      url.value = data.tx.payout.fail_url;
      status.value = EPayStatus.FAIL;
    } else if (
      payoutStatus === "process" ||
      payoutStatus === "queue" ||
      payoutStatus === "waiting" ||
      payoutStatus === "unknown" ||
      payoutStatus === "hold"
    ) {
      status.value = EPayStatus.PROCESS;
    } else if (payoutStatus === "create") {
      const code = data.tx.payout.means_of_payment_type.code;
      if (code === EPayStatus.PAYOUT_CRYPTO) {
        status.value = EPayStatus.PAYOUT_CRYPTO;
      } else if (code === EPayStatus.SBP) {
        status.value = EPayStatus.SBP;
      } else {
        status.value = EPayStatus.PAYOUT;
      }
    } else {
      url.value = null;
      status.value = EPayStatus.FAIL;
    }
  };

  const checkPayment = async (tx: string) => {
    const { data } = await payment.info(tx);

    if (!data.info.payment) {
      return;
    }

    type.value = OperationType.PAYMENT;
    paymentResponse.value = data;
    url.value = data.info.payment.success_url;
    const paymentStatus = data.info.payment.status;
    const integrationType = data.info.payment.integration_type;

    if (
      integrationType === EIntegrationType.REDIRECT &&
      paymentStatus === "create"
    ) {
      status.value = EPayStatus.PROCESS;
      payment.payWithTx(tx).then(({ data }) => {
        window.location.href = data.redirect_url;
      });
      return;
    }

    if (integrationType === EIntegrationType.QR) {
      if (paymentStatus === "create" || paymentStatus === "process") {
        if (
          paymentStatus === "process" &&
          !paymentResponse.value.info.qr_data
        ) {
          status.value = EPayStatus.FAIL;
          return;
        }

        status.value = EPayStatus.QR;
        loading.value = false;

        return;
      }
    }
    if (integrationType === EIntegrationType.P2P_CARD) {
      if (paymentStatus === "create") {
        status.value = EPayStatus.P2P_FORM;
        loading.value = false;
        return;
      }
      if (paymentStatus === "process") {
        status.value = EPayStatus.PROCESS;
        loading.value = false;
        return;
      }
    }

    if (integrationType === EIntegrationType.H2H_P2P) {
      status.value = EPayStatus.H2H_P2P;
      loading.value = false;
      return;
    }

    if (paymentStatus === "success") {
      if (integrationType === EIntegrationType.P2P_CARD) {
        localStorage.removeItem("p2p.card");
        localStorage.removeItem("p2p.expires");
      }
      status.value = EPayStatus.SUCCESS;
    } else if (
      paymentStatus === "error" ||
      paymentStatus === "reversal" ||
      paymentStatus === "antifraud_error" ||
      paymentStatus === "reversal_timeout" ||
      paymentStatus === "provider_timeout"
    ) {
      if (integrationType === EIntegrationType.P2P_CARD) {
        localStorage.removeItem("p2p.card");
        localStorage.removeItem("p2p.expires");
      }
      url.value = data.info.payment.fail_url;
      status.value = EPayStatus.FAIL;
    } else if (
      paymentStatus === "process" ||
      paymentStatus === "queue" ||
      paymentStatus === "waiting" ||
      paymentStatus === "unknown" ||
      paymentStatus === "hold"
    ) {
      if (data.redirect_url && data.redirect_url !== window.location.href) {
        // const [_, tx] = data.redirect_url.split("?tx=");
        // window.location.href = "http://localhost:8080/?tx=" + tx;
        window.location.href = data.redirect_url;
      }
      status.value = EPayStatus.PROCESS;
    } else if (paymentStatus === "create") {
      status.value = EPayStatus.FORM;
    } else {
      url.value = null;
      status.value = EPayStatus.FAIL;
    }
  };

  const paymentInfo = async (tx: string) => {
    try {
      await checkPayout(tx);
      await checkPayment(tx);

      const currentStatus = isPayout.value
        ? payoutResponse.value?.tx.payout?.status
        : paymentResponse.value?.info?.payment?.status;

      if (
        ![...SUCCESS_STATUES, ...FAIL_STATUES].includes(
          currentStatus as TStatus
        ) &&
        !isPayout.value
      ) {
        checkSumChanges(
          paymentResponse.value?.info.payment?.amount,
          paymentResponse.value?.info.payment?.old_amount
        );
      }
    } catch (_) {
      url.value = null;
      status.value = EPayStatus.FAIL;
    } finally {
      loading.value = false;
    }
  };

  const updatePayment = async (tx: string, interval?: number) => {
    try {
      if (isPayout.value) {
        await checkPayout(tx);
      } else {
        await checkPayment(tx);
      }

      const currentStatus = isPayout.value
        ? payoutResponse.value?.tx.payout?.status
        : paymentResponse.value?.info?.payment?.status;
      if (
        [...SUCCESS_STATUES, ...FAIL_STATUES].includes(currentStatus as TStatus)
      ) {
        if (interval) {
          clearInterval(interval);
        }
      } else if (!isPayout.value) {
        checkSumChanges(
          paymentResponse.value?.info.payment?.amount,
          paymentResponse.value?.info.payment?.old_amount
        );
      }
    } catch (_) {
      url.value = null;
      status.value = EPayStatus.FAIL;
    } finally {
      loading.value = false;
    }
  };

  return {
    paymentInfo,
    updatePayment,
    price,
    loading,
    status,
    type,
    url,
    paymentResponse,
    payoutResponse,
    isSumHasChanged,
  };
};
