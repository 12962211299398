import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

import { computed, PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputCrypto',
  props: {
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const validateLatinAndNumbers = (
  e: KeyboardEvent | ClipboardEvent,
  value: string
) => {
  if (/^[A-Za-z\d\s]+$/.test(value)) return true;
  else e.preventDefault();
};

const handleOnPaste = (e: ClipboardEvent) =>
  validateLatinAndNumbers(e, (e.clipboardData as DataTransfer).getData("text"));

const handleOnKeyPress = (e: KeyboardEvent) =>
  validateLatinAndNumbers(e, String.fromCharCode(e.keyCode));

const handleOnChange = (event: Event) => {
  value.value = (event.target as HTMLInputElement).value.replace(
    /[^A-Za-z\d]/g,
    ""
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("input", {
    value: value.value,
    class: "input",
    placeholder: "Введите номер кошелька",
    onInput: handleOnChange,
    onKeypress: handleOnKeyPress,
    onPaste: handleOnPaste
  }, null, 40, _hoisted_1))
}
}

})