import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import OperationWrapper from "@/components/OperationWrapper.vue";
import { ESBPPayoutStep, OperationType } from "@/helpers/enums";
import SBPBanks from "@/components/form/SBPPayout/SBPBanks.vue";
import SBPPayout from "@/components/form/SBPPayout/SBPPayout.vue";
import { usePayment } from "@/use/usePayment";
import { ref } from "vue";
import { TBank } from "@/types/payment";


export default /*@__PURE__*/_defineComponent({
  __name: 'SBPFlow',
  setup(__props) {

const { price } = usePayment();

const step = ref(ESBPPayoutStep.BANKS);
const bank = ref<TBank>();

const changeStep = (value: ESBPPayoutStep) => {
  step.value = value;
};

const changeBank = (value: TBank) => {
  bank.value = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: _unref(price),
    type: _unref(OperationType).PAYOUT,
    "has-cards-block": "",
    "only-pci": ""
  }, {
    default: _withCtx(() => [
      (step.value === _unref(ESBPPayoutStep).BANKS)
        ? (_openBlock(), _createBlock(SBPBanks, {
            key: 0,
            onSelectBank: changeBank,
            onChangeStep: changeStep
          }))
        : (_openBlock(), _createBlock(SBPPayout, {
            key: 1,
            bank: bank.value
          }, null, 8, ["bank"]))
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})