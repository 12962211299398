<template>
  <div class="card-types">
    <template v-for="card of filteredCardTypes" :key="card.icon">
      <svg class="card-types__icon">
        <use :xlink:href="`#${card.icon}`" />
      </svg>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";

const props = defineProps({
  onlyPci: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

interface Card {
  icon: string;
  type: "card" | "standart";
}

const cardTypes: Card[] = [
  { icon: "card-world", type: "card" },
  { icon: "master-card", type: "card" },
  { icon: "visa-card", type: "card" },
  { icon: "pci-card", type: "standart" },
];

const filteredCardTypes = computed(() => {
  return props.onlyPci
    ? cardTypes.filter((card) => card.type === "standart")
    : cardTypes;
});
</script>

<style lang="scss">
.card-types {
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  column-gap: 16px;

  &__icon {
    width: 54px;
    height: 24px;
  }
}
</style>
